import App from '../App'

// pc pages
const index = r => require.ensure([], () => r(require('./../views/Home')), 'home')
const mint = r => require.ensure([], () => r(require('./../views/Mint')), 'mint')
const jack = r => require.ensure([], () => r(require('./../views/Jack')), 'jack')
const simon = r => require.ensure([], () => r(require('./../views/Simon')), 'simon')
const piggy = r => require.ensure([], () => r(require('./../views/Piggy')), 'piggy')
const ralph = r => require.ensure([], () => r(require('./../views/Ralph')), 'ralph')
const roger = r => require.ensure([], () => r(require('./../views/Roger')), 'roger')
const burn = r => require.ensure([], () => r(require('./../views/Burn')), 'burn')
const story = r => require.ensure([], () => r(require('./../views/Story')), 'story')
const stake = r => require.ensure([], () => r(require('./../views/Stake')), 'stake')
const roadmap = r => require.ensure([], () => r(require('./../views/Roadmap')), 'roadmap')
const claim = r => require.ensure([], () => r(require('./../views/Claim')), 'claim')

// mobile pages
const indexM = r => require.ensure([], () => r(require('./../views/HomeM')), 'homeM')
const mintM = r => require.ensure([], () => r(require('./../views/MintM')), 'mintM')
const burnM = r => require.ensure([], () => r(require('./../views/BurnM')), 'burnM')
const stakeM = r => require.ensure([], () => r(require('./../views/StakeM')), 'stakeM')
const roadmapM = r => require.ensure([], () => r(require('./../views/RoadmapM')), 'roadmapM')

export default [{
  path: '/',
  hidden: true,
  component: App,
  children: [
    { path: '', redirect: '/index' },
    { path: '/', redirect: '/index' },
    {
      path: '/index',
      component: index,
      name: 'index',
      meta: {
        mobileWeb: 'indexM',
      }
    },
    {
      path: '/index',
      component: indexM,
      name: 'indexM',
      meta: {
        pcWeb: 'index',
      }
    },
    {
      path: '/mint',
      component: mint,
      name: 'mint',
      meta: {
        mobileWeb: 'mintM',
      }
    },
    {
      path: '/jack',
      component: jack,
      name: 'jack',
      meta: {
        mobileWeb: 'indexM',
      }
    },
    {
      path: '/roger',
      component: roger,
      name: 'roger',
      meta: {
        mobileWeb: 'indexM',
      }
    },
    {
      path: '/ralph',
      component: ralph,
      name: 'ralph',
      meta: {
        mobileWeb: 'ralph',
      }
    },
    {
      path: '/piggy',
      component: piggy,
      name: 'piggy',
      meta: {
        mobileWeb: 'indexM',
      }
    },
    {
      path: '/simon',
      component: simon,
      name: 'simon',
      meta: {
        mobileWeb: 'indexM',
      }
    },
    {
      path: '/burn',
      component: burn,
      name: 'burn',
      meta: {
        mobileWeb: 'burnM',
      }
    },
    {
      path: '/burn',
      component: burnM,
      name: 'burnM',
      meta: {
        pcWeb: 'burn',
      }
    },
    {
      path: '/story',
      component: story,
      name: 'story',
      meta: {
        mobileWeb: 'mintM',
      }
    },
    {
      path: '/mint',
      component: mintM,
      name: 'mintM',
      meta: {
        pcWeb: 'mint',
      }
    },
    {
      path: '/stake',
      component: stake,
      name: 'stake',
      meta: {
        mobileWeb: 'stakeM',
      }
    },
    {
      path: '/stake',
      component: stakeM,
      name: 'stakeM',
      meta: {
        pcWeb: 'stake',
      }
    },
    {
      path: '/roadmap',
      component: roadmap,
      name: 'roadmap',
      meta: {
        mobileWeb: 'roadmapM',
      }
    },
    {
      path: '/roadmap',
      component: roadmapM,
      name: 'roadmapM',
      meta: {
        pcWeb: 'roadmap',
      }
    },
    {
      path: '/claim',
      component: claim,
      name: 'claim',
      // meta: {
      //   mobileWeb: 'roadmapM',
      // }
    },
  ],
}]
