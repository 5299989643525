const cookie = require('./cookie')

const cookieStorage = function () {
}

cookieStorage.getItem = function (key) {
  return cookie.get(key)
}

cookieStorage.setItem = function (key, value) {
  cookie.set(key, value)
}

function getStorage() {
  if (!window.localStorage) {
    console.error('window.localStorage not support')
    cookieStorage.type = 'cookie'
    return cookieStorage
  } else {
    window.localStorage.type = 'localStorage'
    return window.localStorage
  }
}

const storage = getStorage()
export default storage

